.table-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eaedf0;
    cursor: pointer;
    min-height: 50px;
}

.table-row-column {
    border: 1px solid transparent;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.table-row-column p {
    margin: 9px 0px;
    font-size: 15px;
}

.final-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;
}

.status-icon {
    border-radius: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    
    min-width: 25px;
    
}

.status-icon .icon {
    height: 25px;
    width: 25px;
}

.table-row-column {
    border: 1px solid transparent;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.final-container .button-container {
    width: 25%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.final-container .tooltip-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.final-container .tooltip-icon .tooltip-text {
    visibility: hidden;
    background-color: var(--main-color);
    opacity: 60%;
    color: #fff;
    text-align: center;
    padding: 5px 20px;
    border-radius: 6px;
 
    /* Position the tooltip text - see examples below! */
    position: absolute;
    top: -2px;
    right: 30px;
    z-index: 1;
}

.tooltip-icon:hover .tooltip-text {
    visibility: visible;
}

.edit-button:hover{
    color: var(--main-color);
}

.status-value {
    text-align: center;
    width: 100%;
}

.id-value {
    text-overflow: ellipsis;
}