.checkbox-container{
    width: 15px;
    height: 15px;
    margin-right: 3px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #052856;
    cursor: pointer;
}

.checkbox-checked{
    background-color: #052856;
}