.logs-container {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    overflow-y: scroll;
    background-color: #04285512;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    padding-bottom: 90px;
}


.log-card-container {
    width: calc(98% - 6px);
    background-color: aliceblue;
    padding: 3px;
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.log-card-toggle-container {
    width: calc(100%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;
}

.log-card-body-container {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    max-width: calc(90%);
    overflow: hidden;
    opacity: 0;
    display: none;
    max-height: 0px;
}

.topic-text {
    margin: 0px;
    padding: 0px 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.log-text {
    margin: 0px;
    margin-left: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.color-fade {
    animation: fade-color 3s forwards;
    -webkit-animation: fade-color 3s forwards;
}

@keyframes fade-color {
    0% {
       background-color: rgba(255, 217, 0, 0.509);
    }

    100% {
       background-color: aliceblue;
    }
}

.log-open {
    display: flex;
    opacity: 1;
    /* max-height: var(--my-css-var); */
    max-height: 300px;
    animation: expand-log 0.5s forwards;
    -webkit-animation: expand-log 0.5s forwards;
}

.log-close {
    /* opacity: 0;
    max-height: 0px; */
    animation: collapse-log 0.5s forwards;
    -webkit-animation: collapse-log 0.5s forwards;
}

.log-card-toggle-container p {
    margin: 3px;
}


@keyframes expand-log {
    0% {
        max-height: 0px;
    }

    100% {
        max-height: var(--my-css-var);
    }
}

@keyframes collapse-log {
    0% {
        opacity: 1;
        display: flex;
        max-height: var(--my-css-var);
    }

    99% {
        opacity: 0;
        display: flex;
        max-height: 0px;
    }

    100% {
        opacity: 0;
        display: none;
        max-height: 0px;
    }
}