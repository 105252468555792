.data-table-body {
    width: calc(100% - 30px - 2px);
    height: calc(100% - 90px - 30px - 2px);
    margin: 15px;
    background-color: #ffffff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* border: 1px solid #dddcdc; */
    /* -webkit-box-shadow: 3px 3px 9px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 3px 9px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 3px 9px 0px rgba(0,0,0,0.75); */

}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 96%;
    padding: 3%;
}

.loader-container #loader-logo {
    image-rendering: -webkit-optimize-contrast;
    width: 90px;
    opacity: 0.6;
    animation: rotate 1.2s infinite linear;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.table-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 3px;
    /* background-color: #ffffff; */
    border-bottom: 1px solid #c7cacd;
}

.status-header-column {
    text-align: center;
    padding: 0px !important;
}

.status-value {
    text-align: center;
    padding: 0px !important;
}

.header-column {
    border: 1px solid transparent;
    padding-left: 30px;
}

.header-column h4 {
    margin: 9px 0px;
}

.table-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    /* scrollbar-gutter: stable; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.table-body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}