.statistics-graph-container {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  background-color: var(--secondary-color-faded);
}

.statistics-graph-container .dataset-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 325px;
  background-color: white;
  margin: 5px;
  border-radius: 10px;
  padding: 5px;
  gap: 5px;
  border: 1px solid lightgray;
  /* box-shadow: var(--shadow); */
}

.statistics-graph-container .dataset-menu .menu-title {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 3px solid var(--secondary-color-faded);
}

.statistics-graph-container .dataset-menu .menu-title h2 {
  margin: 10px;
}

.statistics-graph-container .dataset-menu .dataset {
  width: calc(100% - 2px - 20px);
  height: auto;
  padding: 0px 10px;
  border: 1px solid lightgray;
  border-radius: 6px;
  background-color: white;
  animation: popIn 0.2s ease;
  overflow: hidden;

  & .graph-color {
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
}

.statistics-graph-container .dataset-menu .add-dataset {
  width: 100%;
  height: 35px;
  border-radius: 6px;
  outline: none;
  border: 0px;
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.statistics-graph-container .dataset-menu .add-dataset[target='enabled'] {
  background-color: var(--main-color);
}

.statistics-graph-container .dataset-menu .add-dataset[target='disabled'] {
  background-color: var(--primary);
}

.statistics-graph-container .graph-container {
  width: 100%;
  background-color: white;
  margin: 5px;
  margin-left: 0px;
  padding: 5px;
  border-radius: 10px;
  /* border: 1px solid lightgray; */
  box-shadow: var(--shadow);
}

.statistics-graph-container .graph-container .toolbar {
  height: 34px;
  width: calc(100% - 8px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 5px 9px 3px;

  border-bottom: 3px solid var(--secondary-color-faded);
}

.statistics-graph-container .graph-container .toolbar .left {
  display: flex;
  width: 50%;
  height: 100%;
  gap: 5px;
}

.statistics-graph-container .graph-container .toolbar .right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 50%;
  height: 100%;
}

.react-datepicker__input-container {
  max-width: 150px;
  width: 100%;
  height: calc(100% - 4px)
}

.statistics-graph-container .graph-container .toolbar input {
  height: calc(100% - 2px);
  padding: 0px 10px;
  outline: none;
  border-radius: 5px;
  border: 1px solid lightgray;
}

@keyframes popIn {
  0% {
    height: 0%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  20% {
    box-shadow: none;
  }

  100% {
    height: 79px;
  }
}
