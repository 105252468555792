.forgot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: whitesmoke;
}

.forgot-container .forgot-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 645px;
    height: 605px;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.forgot-container .forgot-card .logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot-container .forgot-card .logo-container .logo {
    width: 150px;
    height: 150px;
}

.forgot-container .forgot-card .forgot-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 55%;
}

.forgot-container .forgot-card .forgot-form .forgot-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100%;
    width: 100%;
}

.forgot-container .forgot-card .forgot-form .forgot-input {
    width: calc(75% - 40px);
    height: 15%;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 1rem;
    background-color: var(--background);
}

.forgot-container .forgot-card .forgot-form .forgot-button {
    background-color: var(--main-color);
    border: none;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 75%;
    height: 15%;
    font-size: 1rem;
    font-weight: 600;
}