.overview-container {
  position: fixed;
  width: calc(100% - 270px);
  top: calc(100vh);
  /* top: calc(100vh - 30px - 210px); */
  left: 270px;
  height: calc(100vh);
  background-color: white;
  /* border-top-left-radius: 9px; */
  /* border-top-right-radius: 9px; */
  /* border: 1px solid white; */
  overflow: hidden;
  z-index: 2;
}

.pull-up-toggle-container {
  /* height: 30px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background-color: #0428553a;
  /* background: rgb(4, 39, 84); */
  z-index: -1;
}

.pull-up-toggle-container .toggle-button-container {
  width: 300px;
  background-color: #ffffff24;
  cursor: pointer;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  /* height: 30px; */
  flex: 1;
  align-items: center;
}

.pull-up-toggle-container .display-tab-toggle-button-container {
  width: 100%;
  background-color: #f1f1ef;
  cursor: pointer;
  /* border-radius: 30px; */
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* height: 30px; */
  padding: 2px;
  flex: 1;
  align-items: center;
}

.pull-up-toggle-container .display-tab-toggle-button-container .display-tab-toggle-button {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-container-body {
  width: 100%;
  height: calc(100% - 60px);
  background-color: white;
  display: flex;
  flex-direction: column;
}

.checkbox-display {
  border-radius: 100px;
}

.toggle-visibility-container {
  background-color: #f5f5f5;
  padding: 3px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3px;
  gap: 5px;
  border: 1px solid gray;
  border-radius: 30px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

input[type="checkbox" i] {
  cursor: default;
  appearance: none;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: 5px;
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid #052856;
  outline: none;
}

input[type="checkbox" i]:checked {
  background-color: #052856;
  border: 2px solid white;
}

/* #date-picker { */
/*   display: flex; */
/*   flex-direction: row-reverse; */
/*   align-items: center; */
/*   justify-content: center; */
/*   width: 100px; */
/*   height: calc(24px - 8px); */
/*   padding: 3px 15px; */
/* } */

/* #hourly-date-picker { */
/*   display: flex; */
/*   flex-direction: row-reverse; */
/*   align-items: center; */
/*   justify-content: center; */
/*   width: 100px; */
/*   height: calc(100%); */
/*   padding: 3px 15px; */
/* } */

/* #daily-date-picker { */
/*   display: flex; */
/*   flex-direction: row-reverse; */
/*   align-items: center; */
/*   justify-content: center; */
/*   width: 185px; */
/*   height: calc(100%); */
/*   padding: 3px 15px; */
/* } */

.react-datepicker {
  border-radius: 0px !important;
}

.react-datepicker__header__dropdown,
.react-datepicker__header__dropdown--select {
  padding: 3px;
}

.react-datepicker__view-calendar-icon svg {
  height: 100%;
  padding: 0px !important;
  right: 3px;
  fill: #757575;
  cursor: pointer;
}

.overview-card-container {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(260px - 30px - 12px);
  flex-wrap: wrap;
  padding: 15px;
  scrollbar-width: none;
}

.overview-card-container[target='_0'] {
  align-items: center;
}

/* .overview-card-container[target='_0'] .data-display-card-collapsed {
  min-width: 326px;
} */

#sub-slide-up .overview-card-container {
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 95%;
  flex-wrap: nowrap;
  overflow-y: scroll;
}

.top-display-select-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 20px;
  margin-bottom: 10px;
  height: calc(80px - 20px);
}

.top-display-select-container .row {
  /* width: 50%; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


.top-display-select-container p {
  margin: 0px;
}

.card-scroll-container {
  width: 100%;
  margin-top: 6px;
  height: calc(100% - 80px - 6px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: none;
}

.overview-display-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 210px);
  /* background-color: blue; */
}

#sub-slide-up .overview-display-container {
  width: calc(100% - 270px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  /* background-color: red; */
}

.overview-display-top {
  width: calc(100% - 75px - 50px);
  height: 50px;
  margin-top: 10px;
  margin-left: 75px;
  margin-right: 50px;
  /* background-color: orange; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.overview-display-top .overview-header {
  width: 50%;
  /* height: 100%; */
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.overview-display-top .overview-display-top-sub {
  width: 50%;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.overview-display-top .overview-display-top-sub p {
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
}

.input-field {
  width: calc(100% - 25px - 10px);
  height: 15px;
  border-radius: 3px;
  border: none;
  color: #414D55;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid black;
  /* box-shadow: 0px 1px 8px 0px rgba(20, 46, 110, 0.10);
    -webkit-box-shadow: 0px 1px 8px 0px rgba(20, 46, 110, 0.10); */
  -webkit-appearance: none;
  appearance: none;
}

.overview-display-bottom {
  height: calc(100px - 12px);
  width: calc(100% - 50px);
  /* background-color: rgba(0, 0, 0, 0.053); */
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  align-items: flex-start;
  gap: 10px;
  padding: 6px 25px;
}

.device-detail-row {
  width: calc((100% / 3) - 60px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 9px;
  border-radius: 6px;
  border: 1px solid lightgray;
}

/* MEDIA QUERY FOR ROW */
@media (max-width: 1455px) {
  .overview-display-bottom {
    flex-wrap: wrap
  }
}

.device-detail-row p {
  margin: 0px;
}

#hide-show {
  animation: show-up 0.5s forwards;
  -webkit-animation: show-up 0.5s forwards;
}

#show-hide {
  animation: hide-down 0.5s forwards;
  -webkit-animation: hide-down 0.5s forwards;
}

@keyframes show-up {
  0% {
    top: 100vh;
  }

  100% {
    top: calc(100vh - 30px - 210px - 30px);
  }
}

@keyframes hide-down {
  0% {
    top: calc(100vh - 30px - 210px);
  }

  100% {
    top: 100vh;
  }
}

#slide-up {
  animation: slide-up 0.5s forwards;
  -webkit-animation: slide-up 0.5s forwards;
}

#slide-down {
  animation: slide-down 0.5s forwards;
  -webkit-animation: slide-down 0.5s forwards;
}


@keyframes slide-up {
  0% {
    top: calc(100vh - 30px - 210px);
  }

  100% {
    top: 0px;
  }
}

@keyframes slide-down {
  0% {
    top: 0px;
  }

  100% {
    top: calc(100vh - 30px - 240px);
  }
}

@-webkit-keyframes slide-up {
  0% {
    top: calc(100vh - 30px - 210px);
  }

  100% {
    top: 0px;
  }
}

@-webkit-keyframes slide-down {
  0% {
    top: 0px;
  }

  100% {
    top: calc(100vh - 30px - 210px);

  }
}


#sub-slide-up {
  animation: sub-slide-up 0.5s forwards;
  -webkit-animation: sub-slide-up 0.5s forwards;
}

#sub-slide-down {
  animation: sub-slide-down 0.5s forwards;
  -webkit-animation: sub-slide-down 0.5s forwards;
}


@keyframes sub-slide-up {
  0% {
    opacity: 0;
    flex-direction: column;
  }


  50% {
    opacity: 0;
    flex-direction: row-reverse;

  }

  100% {
    opacity: 1;

    flex-direction: row-reverse;
  }
}

@keyframes sub-slide-down {
  0% {
    flex-direction: row-reverse;
    opacity: 0;

  }

  50% {
    opacity: 0;
    flex-direction: column;
  }

  100% {
    flex-direction: column;
    opacity: 1;

  }
}


#slide-up .pull-up-toggle-container {
  animation: color-change 0.5s forwards;
  -webkit-animation: color-change 0.5s forwards;
}

@keyframes color-change {
  0% {
    background-color: #0428553a;

  }

  100% {
    background-color: #042855;

  }
}

/* Collapsed Sidebar Media Query */

@media (max-width: 1200px) {
  .overview-container {
    width: calc(100% - 80px);
    left: 80px;
  }
}

/* ----------------------------- */
