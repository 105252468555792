.control-settings-container {
  width: calc(100%);
  height: calc(100%);
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: row
}

.control-settings-container h1 {
  text-align: center;
}

.control-settings-container .table-container {
  width: 100%
}

.control-settings-container .table {
  display: flex;
  flex-direction: column;
  height: auto;
  width: calc(100% - 40px);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: white;
  padding: 0px 20px 20px 20px;
}

.control-settings-container .logs {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  height: calc(100%);
  width: 50%;
}

.control-settings-container .logs .log-container {
  border: 1px solid lightgray;
  background-color: whitesmoke;
  height: calc(100% - 82px - 15px - 20px);
  margin: 0px 15px;
  border-radius: 10px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 10px;
}

.control-settings-container .logs .log-container .log {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  border: 1px solid lightgray;
  width: calc(100% - 20px);
  margin: 5px 0px;
  padding: 10px;
  border-radius: 5px;
}

.control-settings-container .logs .log-container .log .header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}


.control-settings-container .logs .log-container .log .id {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px
}

.control-settings-container .logs .log-container .log .topic {
  display: flex;
  flex-direction: row;
  gap: 10px
}


.control-settings-container .logs .log-container .log .id h4,
.control-settings-container .logs .log-container .log .id p,
.control-settings-container .logs .log-container .log .topic h4,
.control-settings-container .logs .log-container .log .topic p {
  margin: 2px;
}

.control-settings-container .logs .log-container .log .header h4 {
  margin: 0;
}

.control-settings-container .logs .log-container .log .json-container {
  width: 100%;
  background-color: red
}

.control-settings-container .logs .log-container .log .json-container ._2IvMF {
  background-color: white;
}

.control-settings-container .logs .log-container .log .header .icon {
  width: 25px;
  height: 25px;
}

.control-settings-container .logs .log-container .log:first-of-type {
  margin-top: 0px;
}

.control-settings-container .logs .log-container .log:last-of-type {
  margin-bottom: 0px
}

.control-settings-container .table .row {
  border: 1px solid black;
  border-bottom: 0px;
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  margin: 0;
}

.control-settings-container .table .row input,
.control-settings-container .table .row select {
  width: 100px !important;
}

.control-settings-container .table .row:nth-child(2) {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.control-settings-container .table .row:nth-child(7) {
  border-bottom: 1px solid black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.control-settings-container .table[target='firmware'] .row:nth-child(4) {
  border-bottom: 1px solid black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.control-settings-container .table .row .column:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.control-settings-container .table .row .column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center
}

.control-settings-container .table button {
  width: 125px;
  height: 36px;
  outline: none;
  border: 1px solid var(--main-color);
  background-color: var(--main-color);
  color: white;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.logs .title-header {
  display: flex;
  flex-direction: row;  
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 30px);
  margin: 0px 15px
}

/* CLEAR LOGS TOOLTIP */
.clear-logs-tooltip {
  position: relative;
  top: 1px;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--main-color);
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
}

/* Tooltip text */
.clear-logs-tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--main-color);
  color: #fff;
  opacity: 80%;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  right: 45px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.clear-logs-tooltip:hover .tooltiptext {
  visibility: visible;
}

/* MEDIA QUERIES */
@media (max-width: 1200px) {
  .control-settings-container {
    width: calc(100%);
  }
}

@media (max-width: 1470px) {
  .control-settings-container .logs {
    max-width: 550px;
  }
}
