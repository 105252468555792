/* custom alert modal */

.alert-modal-container {
    position: fixed;
    width: 342px;
    height: 66px;
    /* border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; */
    top: -72px;
    left: calc(50vw - 171px + 135px);
    z-index: 102;
    padding: 3px;
}

.alert-modal-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 3px 3px 3px 1px #00000021;
    -webkit-box-shadow: 3px 3px 3px 1px #00000021;
    -moz-box-shadow: 3px 3px 3px 1px #00000021;
}

.alert-modal-body h5 {
    font-size: 15px;
    width: 80%;
    font-weight: 500
}

.linear-gradient-circle {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin: 0px 14px;
}

#alert-0 {
    opacity: 0.9;
    background: #f66561;
    background: linear-gradient(144deg, rgba(246, 101, 97, 1) 24%, #f34133 76%);
}

#alert-1 {
    opacity: 0.9;
    background: rgb(129, 229, 254);
    background: linear-gradient(144deg, rgba(129, 229, 254, 1) 24%, #75b8fe 76%);
}

#alert-2 {
    opacity: 0.9;
    background: rgb(78, 228, 132);
    background: linear-gradient(144deg, rgba(78, 228, 132, 1) 24%, #46d2a3 76%);
}


#alert-in {
    animation-duration: 1s;
    animation-name: slidein;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

#alert-out {
    animation-duration: 1s;
    animation-name: slideout;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

@keyframes slidein {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(81px);
    }
}

@keyframes slideout {
    0% {
        transform: translateY(81px);
    }

    100% {
        transform: translateY(0px);
    }
}

@media (max-width: 1200px) {
    .alert-modal-container {
        left: calc(50vw - 171px + 40px);
    }
}