.confirm-container {
  /* background-color : blue; */
  background-color: transparent;
  position: absolute;
  top: -15px;
  left: -285px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-container .confirm-modal-background {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.confirm-container .confirm-modal {
  background-color: white;
  z-index: 2;
  width: 260px;
  height: auto;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.confirm-container .confirm-modal .confirm-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 65px;
  margin-bottom: 25px
}

.confirm-container .confirm-modal .confirm-title h1 {
  font-size: 26px;
  margin: 0px;
}

.confirm-container .confirm-modal .update-code {
  font-size: 24px;
  margin: 0;
  font-weight: 600
}


.confirm-container .confirm-modal .confirm-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.confirm-container .confirm-modal .confirm-inputs input ,
.confirm-container .confirm-modal .confirm-inputs button {
  height: 39px;
  outline: none;
  padding: 0px ;
  border: 1px solid black;
  border-radius: 5px;
}

.confirm-container .confirm-modal .confirm-inputs .button-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center; 
}

.confirm-container .confirm-modal .confirm-inputs button {
  cursor: pointer;
  background-color: var(--main-color);
  color: white;
  border: none; 
  border-radius: 5px;
  width: 48%;
}


.confirm-container .confirm-modal .close-btn { 
  cursor: pointer;
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important ; 
}

