.dashboard-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  .dashboard-body {
    width: calc(100% - 270px);
    /* height: calc(100%); */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #d9dee38d;
    /* background: linear-gradient(360deg, rgba(4,39,84,0.18391106442577032) 24%, rgba(255,255,255,1) 66%); */
}

#slide-contract {
  animation: slide-contract 0.5s forwards;
  -webkit-animation: slide-contract 0.5s forwards;
}

#slide-expand {
  animation: slide-expand 0.5s forwards;
  -webkit-animation: slide-expand 0.5s forwards;
}

@keyframes slide-contract {
  0% {
      height: calc(100%);
  }

  100% {
      height: calc(100% - 240px - 30px );
  }
}

@keyframes slide-expand {
  0% {
      height: calc(100% - 240px - 30px);
  }

  100% {
      height: calc(100%);
  }
}


/* Collapsed Sidebar Media Query */
@media (max-width: 1200px) {
  .dashboard-body {
      width: calc(100% - 80px);
  }
}
/* ----------------------------- */