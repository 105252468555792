.not-found-container {
    height: 100dvh;
    width: 100dvw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-found-container .logo-container {
    position: absolute;
    top: 10px;
    left: 10px;
}

.error-code {
    font-size: 15rem;
    color: var(--main-color) ;
    margin: 0;
}

.error-message {
    font-size: 3rem;
    color: var(--text);
    margin-top: 0;
}

.colcab-logo {
    width: 100px;
    height: 100px;
    animation: rotate 10s linear infinite;
}

.navigate-home {
    width: 300px;
    height: 5%;

    top: 10px;
    left: 10px;
    border-radius: 5px;
    border: none;
    background-color: var(--main-color);
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.navigate-home:hover {
    background-color: var(--secondary);
    transition: background-color 0.3s ease-in-out;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
