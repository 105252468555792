.statistics-body-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: all 0.3s;
}

.statistics-body-container[numgraphsdisplayed='1'] {
  flex-direction: column-reverse;
  justify-content: flex-end;
}


.statistics-body-container .statistics-start-container {
  background-color: var(--secondary-color-faded);
  height: calc(100% - 30px);
  width: calc(250px);
  border-radius: 12px;
  padding: 5px;
  margin: 10px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s;
}

.statistics-body-container[numgraphsdisplayed='1'] .statistics-start-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 20px - 10px);
  height: 170px;
  margin: 0px 10px;
  /* border-top-left-radius: 0px;
  border-top-right-radius: 0px; */
}

.statistics-body-container .statistics-start-container .stat-card {
  width: calc(100% - 24px);
  margin-bottom: 5px;
  height: calc(100%);
  padding: 10px 12px;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: white;
  /* box-shadow: var(--shadow); */
}

.statistics-body-container[numgraphsdisplayed='1'] .statistics-start-container .stat-card {
  height: calc(100% - 23px);
  width: calc((100% - 30px)/4 - 24px);
}

.statistics-body-container .statistics-start-container .stat-card:nth-of-type(4) {
  margin: 0;
}

.statistics-body-container .statistics-start-container .stat-card .row {
  display: flex;
  flex-direction: row;
  max-height: 30px;
}

.statistics-body-container[numgraphsdisplayed='1'] .statistics-start-container .stat-card .row {
  display: flex;
  flex-direction: row;
  max-height: 27px;
}

.statistics-body-container .statistics-start-container .stat-card h4 {
  font-size: 23px;
  margin: 0px 0px 6px 0px;
  padding: 0px;
}

.statistics-body-container .statistics-end-container {
  padding: 10px 10px;
  width: calc(100% - 220px);
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 0.3s;
}

.statistics-body-container[numgraphsdisplayed='1'] .statistics-end-container {
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 210px);
}

.statistics-body-container .statistics-end-container .stats-section {
  width: calc(100% - 10px);
  height: calc(50% - 15px);
  padding: 5px;
  border-radius: 12px;
  background-color: var(--secondary-color-faded);
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.statistics-body-container[numgraphsdisplayed='1'] .statistics-end-container .stats-section {
  /* border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; */
  height: calc(50% - 30px);

}

.statistics-body-container[numgraphsdisplayed='1'] .statistics-end-container .stats-section {
  height: calc(100% - 0px);
}

.statistics-body-container .statistics-end-container .stats-section .stats-input-container {
  width: calc(25% - 26px);
  height: calc(100% - 26px);
  overflow-y: scroll;
  display: flex;
  padding: 12px;
  background-color: white;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  border: 1px solid lightgray
}

.statistics-body-container[numgraphsdisplayed='1'] .statistics-end-container .stats-section .stats-input-container {
  width: calc(21% - 31px);
}

.statistics-body-container .statistics-end-container .stats-section .stats-input-container h4 {
  width: 100%;
  font-size: 21px;
  margin: 0px 0px 0px 0px;
}

.statistics-body-container .statistics-end-container .stats-section .stats-graph-container {
  width: calc(75% - 31px);
  height: calc(100% - 26px);
  /* background-color: rgb(226, 12, 12); */
  background-color: white;
  border-radius: 10px;
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray
}

.statistics-body-container .statistics-end-container .stats-section .stats-graph-container .stat-toolbar {
  width: calc(100% - 9px);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
  align-items: center;
  padding-right: 9px;
}

.statistics-body-container .statistics-end-container .stats-section .stats-graph-container .stat-toolbar .check-box-container {
  display: flex;
  flex-direction: row;
  width: 45px;
}

.statistics-body-container[numgraphsdisplayed='1'] .statistics-end-container .stats-section .stats-graph-container {
  width: calc(79% - 31px);
}

.add-remove-graph-button {
  background-color: whitesmoke;
  border-radius: 90px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats-datasets-container {
  border: 1px solid white;
  /* background-color: white; */
  width: 100%;
  height: 100%;
  border-radius: 12px;
  padding: 5px;
}

.datasets-container-card {
  width: calc(100% - 12px);
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 12px;

  border: 1px solid darkgray;
}

/* Media Queries  */

@media (max-width: 1310px) {
  .statistics-body-container .statistics-end-container .datasets-container-card div:nth-child(2) {
    height: auto !important;
  }

  .statistics-body-container .statistics-end-container .datasets-container-card div:nth-child(2) div:nth-child(2) {
    height: 18px !important;
  }
}

.precise-stat {
  padding: 2px;
  width: 42px;
  height: 18px;
  text-align: center;
  margin: 0px 0px 0px 25px;
  border-bottom: 1px solid red;
  border-top: 1px solid red;
  /* border-radius: 6px;
  border: 1px solid red; */
}
