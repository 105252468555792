.dropdown-backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 99;
}


.profile-dropdown {
    width: 250px;
    /* height: 90px; */
    border-radius: 8px;
    cursor: pointer;
    background-color: white;
    border: 1px solid #042754;
    bottom: 60px;
    left: 10px;
    position: absolute;
    z-index: 99;

}

.profile-dropdown .dropdown-item {
    color: #5f6366;
    /* font-family: Poppins; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 5px 0px;
    line-height: normal;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
}

.profile-dropdown .dropdown-item:hover,
.profile-dropdown .dropdown-item:hover svg path {
    color: #042754;
    cursor: pointer;
    fill: #042754;
}
