.data-display-card-collapsed {
    width: calc(20%);
    height: calc(78px + 10px);
    border: 1px solid whitesmoke;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    background: white;
    /* background: rgb(4, 39, 84); */
    /* background: linear-gradient(184deg, rgba(4, 39, 84, 0.18110994397759106) 12%, rgba(255, 255, 255, 1) 100%); */
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    /* box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75); */
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.12);
}

.icc-card {
    width: calc(30%);
    height: calc(180px);
    font-size: 18px;
}

.data-display-card-collapsed p {
    margin: 0px;
}



.data-display-card-collapsed .data-display-start {
    width: calc(100% - 6px);
    padding: 5px;
    /* background-color: #042754; */
    display: flex;
    justify-content: flex-start;
    height: 20px;
}

.data-display-start .edit-icon:hover {
    cursor: pointer;
}

.data-display-card-collapsed .data-display-start h4 {
    margin: 0px;
    color: black;
}

.data-display-card-expanded {
    width: 95%;
    border: 1px solid lightgray;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    background: whitesmoke;
    padding: 3px;
    /* background: linear-gradient(184deg, rgba(4, 39, 84, 0.18110994397759106) 12%, rgba(255, 255, 255, 1) 100%); */
    -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.12);
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.12);
}

.data-display-card-expanded .data-display-start {
    width: calc(100% - 6px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px;
    align-items: center;
    /* background-color: #042754; */
    /* border-right: 1px solid black; */
}

.data-display-card-expanded .data-display-start h4 {
    margin: 0px;
    color: black;
}

.data-display-end {
    width: calc(100% - 18px);
    padding: 3px 9px;
    /* height: calc(100% - 6px); */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.data-display-card-expanded .data-display-end p {
    margin: 0px;
    font-size: 15px;
}


.row {
    margin-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.edit-button-container {
    background-color: #e8e8e8;
    border: 1px solid gray;
    border-radius: 6px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.edit-button-container:active {
    transform: translateY(1px);
}