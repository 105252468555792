.edit-shelf-modal {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-shelf-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 50%;
    background-color: black;

}

.edit-shelf-modal-header {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

}

.edit-shelf-modal-header .close-icon {
    cursor: pointer;
    height: 100%;
}

.edit-shelf-modal-content{
    width: 320px;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    opacity: 100%;
    padding: 20px;
    z-index: 100;
    border-radius: 5px;
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.12);
}
.edit-shelf-modal-body p {
    margin: 0px;
}

.edit-shelf-modal-body {
    width: 100%;
    display: flex;
    gap: 10px;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.edit-shelf-modal-body p {
    width: 100%
}

.edit-shelf-modal-body input {
    width: calc(100% - 10px - 5px);
    height: 40px;
    padding-left: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.edit-shelf-modal-body input:focus {
    outline: none;
    border: 1px solid var(--main-color);
}

.edit-shelf-modal-body button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 10px 0px;
    border: none;
    border-radius: 5px;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    color: white;
    cursor: pointer;
}

