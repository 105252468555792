.relay {
  background-color: red;
}

.legends {
  width: calc(100% - 48px);
  padding-left: 75px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.legends .legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border: 1px solid gray;
  margin: 10px 0px;
  padding: 5px;
  border-radius: 5px;
}

.legends .legend[target='power'] {
  min-width: 90px;
}

.legends .legend[target='factor'] {
  min-width: 55px;
}

.legends .legend[target='temp'] {
  min-width: 85px;
}

.legends .legend p {
  margin: 0
}

.legends .circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.legends .legend[target='power'] .circle {
  background-color: orange;
}

.legends .legend[target='factor'] .circle {
  background-color: green;
}

.legends .legend[target='temp'] .circle {
  background-color: steelblue;
}
