.side-nav {
    width: 270px;
    height: 100%;
    background-color: var(--main-color);
    /* background: linear-gradient(167deg, rgba(4, 39, 84, 1) 21%, rgba(165, 176, 197, 1) 84%); */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    box-shadow: rgba(99, 99, 99, 0.6) 0px 0px 8px 0px;
    user-select: none;
}

.side-nav-body {
    height: calc(100% - 42px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.side-nav-footer {
    height: 52px;
    padding: 0px;
    width: calc(100% - 20px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    gap: 15px;
    /* background-color: rgba(255, 255, 255, 0.786); */
    border-top: 1px solid white;
}

.side-nav hr {
    width: 93%;
}

.side-nav-footer .option-button {
    height: 32px;
    width: 32px;
    border-radius: 100px;
    /* margin-right: 21px; */
    background-color: white;

    /* border: 1px solid #042855; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* -webkit-box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1);
    box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1); */
    cursor: pointer;
}



.header-container {
    width: calc(100% - 20px);
    height: 80px;
    padding-top: 10px;
    margin-bottom: calc(15px - 1px - 12px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid white;
}



#colcab-logo {
    width: 21%;
    background-color: #ffffff;
    border-radius: 100px;
    padding: 6px;
}

#fl-logo {
    width: 30%;
}

.side-nav-menu-item {
    width: calc(100% - 11px);
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff43;
    margin-top: 12px;
    /* border-radius: 6px; */
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    cursor: pointer;
}

.side-nav-menu-item h4 {
    margin: 12px 0px;
    color: #ffffff;
}


.menu-icon {
    margin: 0px 12px;
    color: #ffffff;
}

.menu-left-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Collapsed Sidebar Media Query */
@media (max-width: 1200px) {
    .side-nav {
        width: 80px;
    }

    .side-nav-menu-item h4 {
        display: none;
    }

    #colcab-logo {
        width: 95%;
    }

    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0px;
    }

    .header-container h3 {
        display: none;
    }

    #fl-logo {
        width: 70%;
    }

    .side-nav-footer {
        justify-content: center;
        align-items: center;
    }

    .side-nav-footer .profile-name {
        display: none;
    }

    .side-nav-menu-item {
        height: 50px;
    }
}

/* ----------------------------- */