.sim-card-container {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px;
}

.sim-card-container .sim {
  width: calc(50% - 10px);
  height: calc(50% - 12px - 10px);
  border-radius: 15px;
  padding: 10px;
}

.sim-card-container .sim .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px
}

.sim-card-container .sim .row p,
.sim-card-container .sim .row h3 {
  margin: 10px 0px
}

.sim-card-container .sim button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 49px;
  outline: none;
  border: none;
  border-radius: 17px;
  background-color: var(--main-color);
  color: white;
  cursor: pointer;
  font-weight: 900;
  margin-top: 18px;
  font-size: 16px;
}

.sim-card-container .sim button:hover {
  background-color: #08469e;
  transition: background-color 0.3s ease-in-out
}

.sim-card-container .sim-events {
  width: calc(50%);
  height: calc(100% - 12px - 10px);
  max-width: 557.27px;
  border: 1px solid var(--main-color);
  border-radius: 15px;
  padding: 10px;
  background-color: var(--main-color);
}

.sim-card-container .sim-events .event h3,
.sim-card-container .sim-events .event h4 {
  margin-bottom: 0px;
  margin-top: 16px;
}

.sim-card-container .sim-events .event {
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0px 10px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.sim-card-container .sim-events h1 {
  margin-top: 12px;
  color: white;
}

.box {
  position: relative;
  left: -60px;
  width: auto;
  /* background-color: red; */
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
