.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100dvw;
    height: 100dvh;
    background-color: var(--background);
}

.login-container .login-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 645px;
    height: 605px;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.login-container .login-card .form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 45%;

}

.login-container .login-card .form .input-box {
    width: 75%;
    height: 45px;
    border-radius: 5px;
    border: none;
}

.login-container .login-card .form h2 {
    font-size: 1.5rem;
    margin-bottom: 0;
    margin-top: 40px;
    color: var(--text);
}

.login-container .login-card .form button:hover {
    background-color: #0f2e60;
}

.login-container .login-card .password {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-container .login-card .input-box input {
    width: 80%;
    height: 90%;
    border: none;
    font-size: 1rem;
    background-color: var(--background);

}

.login-container .login-card .form .input-box:focus,
.login-container .login-card .input-box input:focus {
    outline: none;
}

.login-container .login-card .form .input-box {
    width: calc(75% - 40px);
    padding: 0 20px;
    font-size: 1rem;
    background-color: var(--background);
}

.login-container .login-card .visibility-icon {
    color: var(--text);
}

.login-container .login-card .visibility-icon:hover {
    cursor: pointer;
}

.login-container .login-card .login-container {
    height: 30%;
}

.login-container .login-card .logo-container {
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.login-container .logo {
    width: 150px;
    height: 150px;
    animation: rotate 10s infinite linear;

}

.login-container .login-card .bottom-text {
    margin: 0;
    font-size: 0.8rem;
    color: var(--secondary);
}

.login-container .login-card .remember,
.login-container .login-card .forgot {
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--background);
}

/* Hide the password reveal button in Edge */
::-ms-reveal {
    display: none
}

@media (max-width: 685px) {
    .login-container .login-card {
        box-shadow: none;
        width: 100%;
    }

    .login-container {
        background-color: white;
    }

}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.04);
    }

    100% {
        transform: scale(1.0);
    }
}


.login-submit-btn {
    background-color: var(--main-color);
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 75%;
    height: 45px;
    font-weight: 600;
}


.login-submit-btn span {
    display: flex !important;
    align-items: center;
    justify-content: center;
}