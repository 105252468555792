.statistics-graph .line {
  z-index: 100;
}

.statistics-graph .bar {
  z-index: 1;
}

.statistics-graph .time-box {
  background-color: red !important;
  width: 200px;
}


