.backdrop {
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  position: absolute;
  z-index: 100;
}

.sim-card-container .backdrop {
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  position: absolute;
  z-index: 100;
  top: 0px;
  left: 0px
}

.sim-card-container .modal-body {
   position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 420px;
  background-color: white;
  border: 2px solid #042754;
  border-radius: 6px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  z-index: 99;

}

.modal-body {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 420px;
  background-color: white;
  border: 2px solid #042754;
  border-radius: 6px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  z-index: 99;
}

.confirm-modal-body {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: white;
  border: 0px solid #ff0000;
  border-radius: 6px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  z-index: 99;
}

.modal-header {
  width: calc(100% - 18px);
  padding: 0px 9px;
  /* border-bottom: 2px solid #375477; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  background-color: #f6fafe;
}

/* .modal-header h3 {
    color: #000000;
} */

.modal-title {
  margin: 0px;
  font-weight: 700;
  color: var(--main-color);
  text-align: center;
}

.input-form {
  margin-top: 15px;
  margin-bottom: 15px;

  /* padding: 15px; */
}

.form-group-vertical {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 30px);
  padding: 9px 15px;
}

.form-group-vertical .col-form-label {
  font-size: 15px;
  font-weight: 400;
}

.form-group-vertical .modal-input {
  border: 1px solid #ababab;
  background-color: transparent;
  border-radius: 3px;
  padding: 0px;
  padding-left: 3px;
  height: 27px;
  width: 198px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.submit-container {
  width: calc(100% - 30px);
  padding: 9px 15px;
  /* height: 30px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f6fafe;
}


.submit-container .remove-btn {
  background-color: white;
  color: red;
  border: 1px solid red;
  cursor: pointer;

}

.aus-submit-container {
  width: calc(100%);
  /* height: 60px; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #f6fafe;
}

.aus-submit-btn {
  margin: 9px 9px;
  width: 40%;
  height: 36px;
  color: white;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
}

.modal-submit-btn {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  background-color: #042754c2;
  color: white;
  border: 1px solid #042754;
  border-radius: 6px;
  font-weight: 500;
  -webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.modal-submit-btn:active {
  transform: translateY(1px);
  box-shadow: 0px;
}
