.searchbar-container {
    height: 30px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    padding: 3px 15px;
    border: 1px solid white;
    /* margin: 0px 30px; */
    margin-right: 30px;
    width: 300px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* -webkit-box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1);
    box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1); */
}

.searchbar {
    margin: 0;
    padding: 4px;
    border-radius: 3px 0 0 3px;
    background-color: transparent;
    color: black;
    border: 2px solid transparent;
    font-weight: 500;
    /* width: 270px; */
    width: 100%;

}

.searchbar:focus {
    outline: none;
    /* background-color: #efefef; */
}

.search-button {
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
    border-left: 0px;
    border-radius: 0 3px 3px 0;
    height: 23px;
    cursor: pointer;
}