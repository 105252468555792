.top-toolbar {
    width: calc(100% - 30px);
    height: 90px;
    padding: 0px 15px;
    /* background-color: #838080; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.data-tools-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.top-toolbar .table-title {
    color: #000000;
    font-size: 24px;
    font-weight: 600;
    margin: 0px;
    background-color: #ffffff;
    /* -webkit-box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1);
    box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1); */
    /* border: 1px solid gray; */
    padding: 3px 18px;
    border-radius: 20px;
}

.sort-filter-container {
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    border-radius: 6px;
    border: 1px solid white;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sort-filter-container .tool-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 9px;
}

.sort-filter-container .tool-container h5 {
    margin: 0px 6px;
    font-weight: 500;
}

.sort-filter-container .order-container {
  display: flex;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  opacity: 1;
  padding: 3px;
  height: 16px;
  border-radius: 6px;
  cursor : pointer;
}

.action-container .tooltip-icon,
.sort-filter-container .tooltip-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.action-container .tooltip-icon .tooltip-text,
.sort-filter-container .tooltip-icon .tooltip-text {
    visibility: hidden;
    background-color: var(--main-color);
    opacity: 60%;
    color: #fff;
    text-align: center;
    padding: 5px 20px;
    border-radius: 6px;
    text-wrap: nowrap;
    position: absolute;
    z-index: 1;
}

.sort-filter-container .tooltip-icon .tooltip-text {
    /* Position the tooltip text - see examples below! */
    top: 30px;
    right: 0px;
    
}

.action-container .tooltip-icon .tooltip-text {
    /* Position the tooltip text - see examples below! */
    top: 40px;
    right: 0px;
}

.tooltip-icon:hover .tooltip-text {
    visibility: visible;
}


.order-icon {
  height: 100%;
  stroke: white;
  stroke-width: 2px;
}


.sort-filter-dropdown {
    border: 0px;
    height: 24px;
    padding: 3px;
    width: 100px;
    /* background-color: #f5f5f5; */
    border-radius: 6px;
    border: 1px solid white;
    background-color: #eaedf0;
    /* -webkit-box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1);
    box-shadow: inset 3px 3px 9px 0px rgba(224, 224, 224, 1); */
    cursor: pointer;
}

.filter-radio-btn {
    font-size: 12px;
    margin: 3px;
    border: 1px solid #7a7a7a;
    border-radius: 100px;
    padding: 3px 6px;
    background-color: white;
    color: #7a7a7a;
    cursor: pointer;
    font-weight: 600;
}

.filter-radio-btn-selected {

    border: 1px solid var(--main-color);
    padding: 3px 6px;
    background-color: var(--main-color);
    color: #ffffff;
}

.action-container {
    display: flex;
    flex-direction: row;
    /* width: 200px; */
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
}

.add-record-button {
    background-color: var(--main-color);
    border: 2px solid var(--main-color);
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
}
.add-record-button:active {
    transform: translateY(1px);
    box-shadow: none;

}

.add-record-button h5 {
    font-size: 20px;
    font-weight: 500;
    padding: 0;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-color);
}

/* Media quries */

@media (max-width: 1333px) {

    .top-toolbar .data-tools-container .searchbar-container {
        width: calc(50% - 30px);
        margin-right: 15px;
    }

    .top-toolbar .data-tools-container .sort-filter-container .tool-container {
        margin: 0px 6px
    }

}

@media (max-width: 1083px) {
    .top-toolbar .data-tools-container {
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
    }

    .top-toolbar .data-tools-container .searchbar-container {
        height: 27px;
        width: calc(100% - 30px);
        margin-right: 0px;
    }

    .top-toolbar .data-tools-container .sort-filter-container {
        height: 27px;
        width: 100%;
    }



}
