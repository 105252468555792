    .logo-container #wifi-1 {
        animation: flicker-1 infinite 3s linear;

    }

    .logo-container #wifi-2 {
        animation: flicker-2 infinite 3s linear;

    }

    .logo-container #wifi-3 {
        animation: flicker-3 infinite 3s linear;

    }


    @keyframes flicker-1 {
        0% {
            fill: transparent;
        }

        25% {
            fill: transparent;
        }

        50% {
            fill: transparent;
        }

        75% {
            fill: #0b51b9;
        }

        100% {
            fill: #0b51b9;
        }
    }

    @keyframes flicker-2 {
        0% {
            fill: transparent;
        }

        25% {
            fill: transparent;
        }

        50% {
            fill: #0b51b9;
        }

        75% {
            fill: #0b51b9;
        }

        100% {
            fill: #0b51b9;
        }
    }

    @keyframes flicker-3 {
        0% {
            fill: transparent;
        }

        25% {
            fill: #0b51b9;
        }

        50% {
            fill: #0b51b9;
        }

        75% {
            fill: #0b51b9;
        }

        100% {
            fill: #0b51b9;
        }
    }